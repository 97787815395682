<template>
    <div :class="{'ly-is-full':isFull}">
        <div class="tableSelect" ref="tableSelect">
            <el-form :inline="true" :model="formInline" label-position="left">
                <el-form-item>
                    <el-input v-model.trim="formInline.order_no" maxlength="60"  clearable placeholder="订单编号" @change="search" style="width:130px"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input v-model.trim="formInline.c_name" maxlength="60"  clearable placeholder="顾客姓名" @change="search" style="width:130px"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input v-model.trim="formInline.c_mobile" maxlength="60"  clearable placeholder="顾客手机号" @change="search" style="width:130px"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input v-model.trim="formInline.agent_mobile" maxlength="60"  clearable placeholder="渠道手机号" @change="search" style="width:130px"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-date-picker
                            style="width:350px"
                            v-model="timers"
                            type="datetimerange"
                            @change="timeChange"
                            range-separator="至"
                            start-placeholder="订单开始日期"
                            end-placeholder="订单结束日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="" v-show="hasPermission(this.$route.name,'Search')"><el-button  @click="search" type="primary" icon="Search">查询</el-button></el-form-item>
                <el-form-item label=""><el-button  @click="handleEdit('','reset')" icon="Refresh">重置</el-button></el-form-item>
                <!-- <el-form-item label="" @click="clickMore" v-if="!showOtherSearch">
                    <span class="lysearchmore">展开
                        <el-icon><ArrowDown /></el-icon>
                    </span>
                </el-form-item>
                <el-form-item label="" @click="clickMore" v-if="showOtherSearch">
                    <span class="lysearchmore">收起
                        <el-icon><ArrowUp /></el-icon>
                    </span>
                </el-form-item> -->
            </el-form>
        </div>
        <ul class="order-static" ref="orderStatic">
            <li>总订单量：{{orderstatics.totalcount}} 单</li>
            <li>合计总额：¥{{orderstatics.totalmoney}}</li>
        </ul>
        <el-table :height="tableHeight" border :data="tableData" ref="tableref" v-loading="loadingPage" style="width: 100%" @selection-change="handleSelectionChange">
            <!-- <el-table-column type="selection" width="55"></el-table-column> -->
            <el-table-column type="index" width="60" align="center" label="序号">
                <template #default="scope">
                    <span v-text="getIndex(scope.$index)"></span>
                </template>
            </el-table-column>
            <el-table-column width="160" prop="order_no" label="订单编号" show-overflow-tooltip></el-table-column>
            <el-table-column width="180"  label="顾客姓名" show-overflow-tooltip>
                <template #default="scope">
                    <div>{{scope.row.customerinfo.name}}</div>
                </template>
            </el-table-column>
            <el-table-column width="180"  label="顾客电话" show-overflow-tooltip>
                <template #default="scope">
                    <div>{{scope.row.customerinfo.mobile}}</div>
                </template>
            </el-table-column>
            <el-table-column min-width="100" prop="total_amount"  label="项目金额">
                <template #default="scope">
                    ¥{{scope.row.total_amount}}
                </template>
            </el-table-column>
            <el-table-column min-width="160"  label="渠道名称" show-overflow-tooltip>
                <template #default="scope">
                    <div>{{scope.row.agentinfo.name}}</div>
                </template>
            </el-table-column>
            <el-table-column min-width="160"  label="渠道电话" show-overflow-tooltip>
                <template #default="scope">
                    <div>{{scope.row.agentinfo.mobile}}</div>
                </template>
            </el-table-column>
            <el-table-column width="180" prop="create_datetime"  label="创建时间" show-overflow-tooltip></el-table-column>
        </el-table>
        <Pagination v-bind:child-msg="pageparm" @callFather="callFather"></Pagination>
    </div>
</template>
<script>
    import Pagination from "@/components/Pagination";
    import {dateFormats,deepClone} from "@/utils/util";
    import {yimeiOrder,yimeiOrderDelete,yimeiOrderStatic,yimeiOrderPassdeny} from '@/api/api'
    import LyDialog from "@/components/dialog/dialog";
    import { lyMixins } from "@/mixins/mixins"
    export default {
        mixins: [lyMixins],
        components:{
            Pagination,
            LyDialog
        },
        name:'lyAgentOrderStatics',
        data() {
            return {
                isFull:false,
                loadingPage:false,
                defaultImg:require('../../assets/img/avatar.jpg'),
                showOtherSearch:false,//隐藏过长的搜索条件
                // 选项框选中数组
                ids: [],
                // 选项框非单个禁用
                single: true,
                // 非多个禁用
                multiple: true,
                formInline:{
                    name:'',
                    status:'',
                    page: 1,
                    limit: 10,
                },
                pageparm: {
                    page: 1,
                    limit: 10,
                    total: 0
                },
                orderstatics:{
                    totalmoney: 0,
                    totalAuditcount:0,
                    totalamountarrears:0,
                    totalamountdeposit:0,
                    totalcount: 0,
                },
                statusList:[
                    {id:1,name:'审核中'},
                    {id:2,name:'已通过'},
                    {id:3,name:'未通过'},
                ],
                timers:[],
                tableData:[],
                dialogVisible:false,
                dialogTitle:"审核",
                formData:{
                    id:'',
                    action:'pass',
                    audit_remarks:'',
                },
                dialogLoadingSave:false,
            }
        },
        methods:{
            tagType(status){
                if(status == 2){
                    return 'success'
                }else if(status == 3){
                    return 'danger'
                }else if(status == 1){
                    return ''
                }
                return ''
            },
            clickMore(){
                this.showOtherSearch = !this.showOtherSearch
                window.dispatchEvent(new Event('resize'))
            },
            /**
             * 从URL里下载文件
            */
            // 下载文件
            downloadFileURL(url) {
                var iframe =document.createElement("iframe")
                iframe.style.display ="none";
                iframe.src = url;
                document.body.appendChild(iframe);
            },
            // exportDataBackend() {
            //     var params = {
            //         page: 1,
            //         limit: 9999,
            //     }
            //     jizhanGoodsorderExport(params).then(res => {
            //          if(res.code ==2000) {
            //              this.downloadFileURL(res.data)
            //          }
            //      })
            // },
            setFull(){
                this.isFull=!this.isFull
                window.dispatchEvent(new Event('resize'))
            },
            // 表格序列号
            getIndex($index) {
                // (当前页 - 1) * 当前显示数据条数 + 当前行数据的索引 + 1
                return (this.pageparm.page-1)*this.pageparm.limit + $index +1
            },
            //多选项框被选中数据
            handleSelectionChange(selection) {
                this.ids = selection.map(item => item.id);
                this.single = selection.length !== 1;
                this.multiple = !selection.length;
            },
            /** 批量删除按钮操作 */
            handleDelete(row) {
                const ids = this.ids;
                let vm = this
                vm.$confirm('是否确认删除选中的数据项?', "警告", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }).then(function() {
                    return yimeiOrderDelete({id:ids}).then(res=>{
                        if(res.code == 2000) {
                            vm.$message.success(res.msg)
                            vm.search()
                        } else {
                            vm.$message.warning(res.msg)
                        }
                    })
                })
            },
            handleEdit(row,flag) {
                let vm = this
                if(flag=='delete') {
                    vm.$confirm('确定删除该订单？删除后无法恢复？',{
                        closeOnClickModal:false
                    }).then(res=>{
                        yimeiOrderDelete({id:row.id}).then(res=>{
                            if(res.code == 2000) {
                                vm.$message.success(res.msg)
                                vm.search()
                            } else {
                                vm.$message.warning(res.msg)
                            }
                        })
                    }).catch(()=>{

                    })
                }
                else if(flag=="audit"){
                    this.dialogVisible = true
                    this.$nextTick(()=>{
                        this.formData.id = row.id
                    })
                }
                else if(flag=="reset"){
                    this.formInline = {
                        page:1,
                        limit: 10
                    }
                    this.pageparm={
                        page: 1,
                        limit: 10,
                        total: 0
                    }
                    this.timers = []
                    this.search()
                }
            },

            callFather(parm) {
                this.formInline.page = parm.page
                this.formInline.limit = parm.limit
                this.getData()
            },
            search() {
                this.formInline.page = 1
                this.formInline.limit = 10
                this.getData()
                this.getOrderstatistics()
            },
            //订单金额统计获取
            getOrderstatistics(){
                let tempdata = deepClone(this.formInline)
                tempdata.status = 2
                yimeiOrderStatic(this.formInline).then(res => {
                     if(res.code ==2000) {
                         this.orderstatics = res.data.data
                     }
                 })
                  //   .finally(() => {
                  //  this.$nextTick(() => {
                  //     this.$refs.tableref.doLayout();
                  //   });
                  // });
            },
            //获取列表
            async getData() {
                this.loadingPage = true
                let tempdata = deepClone(this.formInline)
                if(!!this.$route.query.creator_id){
                    tempdata.creator_id = this.$route.query.creator_id
                }
                if(!!this.$route.query.headnurse_id){
                    tempdata.headnurse_id = this.$route.query.headnurse_id
                }
                tempdata.status = 2
                yimeiOrder(tempdata).then(res => {
                     this.loadingPage = false
                     if(res.code ==2000) {
                         this.tableData = res.data.data
                         this.pageparm.page = res.data.page;
                         this.pageparm.limit = res.data.limit;
                         this.pageparm.total = res.data.total;
                     }
                 })
            },

            timeChange(val){
                if (val) {
                    this.formInline.beginAt=dateFormats(val[0],'yyyy-MM-dd hh:mm:ss');
                    this.formInline.endAt=dateFormats(val[1],'yyyy-MM-dd hh:mm:ss');
                } else {
                    this.formInline.beginAt = null
                    this.formInline.endAt = null
                }
                this.search()
            },
            dialogHandleClose(){
                this.dialogVisible = false
                this.formData = {
                    id:'',
                    action:'pass',
                    audit_remarks:'',
                }
            },
            auditSubmitData() {
                this.$refs['dialogRulesForm'].validate(obj=>{
                    if(obj) {
                        if(this.formData.action === 'deny' && this.formData.audit_remarks === ""){
                            this.$message.warning("未通过原因必填项")
                            return
                        }
                        this.dialogLoadingSave=true
                        yimeiOrderPassdeny(this.formData).then(res=>{
                            this.dialogLoadingSave=false
                            if(res.code ==2000) {
                                this.$message.success(res.msg)
                                this.dialogHandleClose()
                            } else {
                                this.$message.warning(res.msg)
                            }
                        })
                    }
                })
            },
        },
        created() {
            this.getData()
            this.getOrderstatistics()
        },
    }
</script>
<style lang="scss">
    .order-static{
        display: flex;
        font-size: 14px;
        background: var(--l-order-statics);
        padding: 10px;
        li{
            margin-right: 30px;

        }
    }
</style>
