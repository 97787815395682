<template>
    <div>
        <ly-crud ref="lycrud"  v-bind="crudConfig" >
            <template v-slot:customHandle="slotProps">
                <span class="table-operate-btn" @click="handleClick(slotProps.row,'disable')" v-show="hasPermission(this.$route.name,'Disable')">
                    <span v-if="slotProps.row.is_active">禁用</span>
                    <span v-else>启用</span>
                </span>
            </template>
        </ly-crud>
    </div>
</template>

<script lang="jsx">
    import {UsersMryusers,UsersMryusersAdd,UsersMryusersDelete,UsersMryusersEdit,appGetAgentList,UsersMryusersdisableEdit} from '@/api/api'
    import LyCrud from "@/components/lycrud";
    import templateData from "@/components/dict/crudTemplateData"
    import { h,resolveComponent } from 'vue';
    export default {
        name: "beautyShopManage",
        components: {LyCrud},
        data(){
            let agentList = this.getAgentList()//使data中变量能调用
            return{
                defaultImg:require('../../assets/img/avatar.jpg'),
                //crud配置
                crudConfig:{
                    //crud请求方法配置
                    crudRequest:{
                        add:UsersMryusersAdd,
                        del:UsersMryusersDelete,
                        edit:UsersMryusersEdit,
                        search:UsersMryusers,
                    },
                    //搜索栏目配置
                    searchBar:{
                        showSearchBar:true,//显示搜索栏目
                        searchColums:[
                            {label:'名称',type:'input',prop:'name',width:200,maxlength:60,placeholder:'请输入名称'},
                            {label:'手机号',type:'input',prop:'mobile',width:200,maxlength:60,placeholder:'请输入手机号'},
                            {label:'所属代理',type:'select',prop:'parent_id',width:200,placeholder:'请选择',
                                options:agentList
                            },
                        ]
                    },
                    //显示分页
                    showPagination:true,
                    //分页配置
                    pageparams: {
                        limit: 10,//每页显示的条数(默认每页显示10条)//非必填
                        //pageSizes:[10,20,30,40,50,200],//非必填
                        // layout:'total, sizes, prev, pager, next, jumper',//非必填
                    },
                    //crud按钮配置
                    rowHandle:{
                        width: 180,//操作列宽度,0表示不显示表格操作列
                        fixed:"right",//固定操作列在右侧
                        permission:{//增删改查按钮权限控制（是否显示）
                            add:this.hasPermission(this.$options.name,'Create'),//bool型 this.$options.name获取当前组件的name本例为userManageCrud
                            del:this.hasPermission(this.$options.name,'Delete'),
                            edit:this.hasPermission(this.$options.name,'Update'),
                            search:this.hasPermission(this.$options.name,'Search'),
                            detail:false,
                        }
                    },
                    //crud弹窗属性
                    formOptions: {
                        width:'45%',//dialog弹窗宽度：类型：百分比或字符串
                        gutter: 20, // Layout布局栅格间隔
                    },
                    //crud表格属性
                    tableOptions:{
                        border:true,
                        showHeader:true,
                    },
                    showSelectable:true,//表格显示复选项框
                    //table表头列
                    tableColumns:[
                         // {label:'用户头像',type:'image',prop:'avatar',minWidth:'60',sortable: false,hidden:false,render:(row)=>{
                         //     let elImage = resolveComponent('el-image')//全局组件需要先resolveComponent解析该组件再render渲染，不然该标签会出现原样输出
                         //     return h(elImage,{
                         //          src:row.avatar ? row.avatar : this.defaultImg,
                         //          previewSrcList:[row.avatar ? row.avatar : this.defaultImg],//开启预览，原preview-src-list属性在h渲染方法中，-后字母大写代替即可：previewSrcList
                         //          previewTeleported:true,//插入至body元素上
                         //          style:"width: 30px;height: 30px",
                         //     },)
                         //  }
                         // },
                        {label:'ID',prop:'id',type:'input',minWidth:'100',sortable: false,hidden:true,
                            form:{
                                 //表单属性
                                 span:24,
                                 hidden:true,//编辑时隐藏，添加时去除
                             }
                        },
                        {label:'所属代理',prop:'parent_id',type:'select',minWidth:'150',sortable: false,hidden:false,
                            render:(row)=>{
                                //table显示样子
                                return row.agentinfo.id != ''?row.agentinfo.name+':' +row.agentinfo.mobile:''
                            },
                            form:{
                                //表单属性
                                span:24,
                                options:agentList,
                            }
                        },
                        {label:'用户名(手机号)',prop:'username',type:'input',minWidth:'110',sortable: false,hidden:false,
                            form:{
                                //表单属性
                                span:24,
                                rules: [{ required: true, message: '用户名必填项' }],
                                placeholder: '请输入用户名',
                            }
                         },
                         {label:'名称',prop:'name',type:'input',minWidth:'110',sortable: false,hidden:false,
                            form:{
                                //表单属性
                                span:24,
                                rules: [{ required: true, message: '美容院名称必填项' }],
                                placeholder: '请输入美容院名称',
                            }
                         },
                         {label:'密码',prop:'password',type:'input',minWidth:'110',sortable: false,hidden:true,
                            form:{
                                //表单属性
                                span:24,
                                rules: [{ required: true, message: '密码必填项' }],
                                placeholder: '请输入密码',
                                showPassword:true,
                            }
                         },
                        //  {label:'是否转介绍',prop:'is_zhuan',type:'radio',minWidth:'100',sortable: false,hidden:false,
                        //      render: (row) =>{
                        //         let elTag = resolveComponent('el-tag')//全局组件需要先resolveComponent解析该组件再render渲染，不然该标签会出现原样输出
                        //         if(!row.is_zhuan){
                        //             return h(elTag, {
                        //               type:'warning'
                        //             },()=>'否');//用()=>函数方式返回文本值，避免出现console警告：默认槽位遇到非函数值
                        //         }else {
                        //             return h(elTag, {
                        //               type:'success'
                        //             },()=>'是');
                        //         }
                        //       },
                        //      form:{
                        //          //表单属性
                        //          span:24,
                        //          options:templateData.WHETHER_BOOL,//radio选项属性值
                        //          defaultValue:false,//新增时的默认值
                        //          // valueChange(e){//值变化回调事件
                        //          //     console.log(e,'---变化的值')
                        //          // },
                        //      }
                        //  },
                         {label:'状态',prop:'is_active',type:'radio',minWidth:'100',sortable: false,hidden:false,
                             render: (row) =>{
                                let elTag = resolveComponent('el-tag')//全局组件需要先resolveComponent解析该组件再render渲染，不然该标签会出现原样输出
                                if(!row.is_active){
                                    return h(elTag, {
                                      type:'warning'
                                    },()=>'禁用');//用()=>函数方式返回文本值，避免出现console警告：默认槽位遇到非函数值
                                }else {
                                    return h(elTag, {
                                      type:'success'
                                    },()=>'正常');
                                }
                              },
                             form:{
                                 //表单属性
                                 span:24,
                                 options:templateData.STATUS_BOOL,//radio选项属性值
                                 defaultValue:true,//新增时的默认值
                                 // valueChange(e){//值变化回调事件
                                 //     console.log(e,'---变化的值')
                                 // },
                             }
                         },
                         {label:'创建时间',prop:'create_datetime',minWidth:'150',sortable: false,hidden:false},
                    ],
                },

            }
        },
        methods:{
            setFull(){
                this.$refs.lycrud.setFull()
            },
            getAgentList(){
                let slist = []
                appGetAgentList({page:1,limit:999}).then(res=>{
                    if(res.code === 2000){
                        res.data.data.forEach(item=>{
                            slist.push({
                                value:item.id,
                                label:item.name+":"+item.mobile
                            })
                        })
                    }
                })
                return slist
            },
            //自定义操作列按钮方法
            handleClick(row,flag){
                let vm = this
                if(flag=='disable'){
                    UsersMryusersdisableEdit({id:row.id}).then(res=>{
                        if(res.code == 2000) {
                            vm.$message.success(res.msg)
                            vm.$refs.lycrud.handleRefresh()//刷新表格数据
                        } else {
                            vm.$message.warning(res.msg)
                        }
                    })
                }
            },
        },
    }
</script>

<style scoped>

</style>