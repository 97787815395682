<template>
    <div :class="{'ly-is-full':isFull}">
        <div class="tableSelect" ref="tableSelect">
            <el-form :inline="true" :model="formInline" label-position="left">
                <el-form-item label="订单编号：">
                    <el-input v-model.trim="formInline.order_no" maxlength="60"  clearable placeholder="订单编号" @change="search" style="width:150px"></el-input>
                </el-form-item>
                <el-form-item label="顾客电话：">
                    <el-input v-model.trim="formInline.customer_mobile" maxlength="60"  clearable placeholder="顾客电话" @change="search" style="width:150px"></el-input>
                </el-form-item>
                <!-- <el-form-item label="时间：">
                    <el-date-picker
                            style="width:350px"
                            v-model="timers"
                            type="datetimerange"
                            @change="timeChange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期">
                    </el-date-picker>
                </el-form-item> -->
                <el-form-item label="回访状态：">
                    <el-select v-model="formInline.is_over" placeholder="请选择" clearable @change="search" style="width:100px">
                        <el-option
                                v-for="item in statusList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label=""><el-button  @click="search" type="primary" icon="Search" v-show="hasPermission(this.$route.name,'Search')">查询</el-button></el-form-item>
                <el-form-item label=""><el-button  @click="handleEdit('','reset')" icon="Refresh">重置</el-button></el-form-item>
            </el-form>
        </div>
        <el-table :height="tableHeight" border :data="tableData" ref="tableref" v-loading="loadingPage" style="width: 100%">
            <el-table-column type="index" width="60" align="center" label="序号">
                <template #default="scope">
                    <span v-text="getIndex(scope.$index)"></span>
                </template>
            </el-table-column>
            <el-table-column width="160" prop="orderinfo.order_no" label="订单编号"></el-table-column>
            <el-table-column width="130" prop="customerinfo.name" label="顾客姓名" show-overflow-tooltip></el-table-column>
            <el-table-column width="140" prop="customerinfo.mobile" label="顾客电话"></el-table-column>
            <el-table-column width="170" prop="orderinfo.agent_name" label="渠道名称"></el-table-column>
            <el-table-column width="140" prop="orderinfo.agent_mobile" label="渠道电话" show-overflow-tooltip></el-table-column>
            <el-table-column width="110" prop="nums" label="第N次回访"></el-table-column>
            <el-table-column width="110" prop="is_over" label="回访状态">
                <template #default="scope">
                    <el-tag v-if="scope.row.is_over" type="">已回访</el-tag>
                    <el-tag v-else type="danger">待回访</el-tag>
                </template>
            </el-table-column>
            <el-table-column min-width="180" prop="orderinfo.intention_desc" label="客户意向" show-overflow-tooltip></el-table-column>
            <!-- <el-table-column min-width="180" prop="result" label="回访结果" show-overflow-tooltip></el-table-column> -->
            <el-table-column label="操作" fixed="right" width="180">
                <template #default="scope">
                    <span class="table-operate-btn" @click="handleEdit(scope.row,'audit')" v-show="hasPermission(this.$route.name,'ReturnVisit')">回访</span>
                </template>
            </el-table-column>
        </el-table>
        <Pagination v-bind:child-msg="pageparm" @callFather="callFather"></Pagination>
        <returnvisitModule ref="returnVisitModuleFlag" @refreshData="getData"></returnvisitModule>
    </div>
</template>
<script>
    import Pagination from "@/components/Pagination";
    import {dateFormats,hasPermission} from "@/utils/util";
    import {yimeiReturnvisit} from '@/api/api'
    import { lyMixins } from "@/mixins/mixins"
    import returnvisitModule from "./components/returnvisitModule";
    import {useMutitabsStore} from "@/store/mutitabs";

    export default {
        setup(){
            const mutitabsStore = useMutitabsStore()
            return {mutitabsStore}
        },
        mixins: [lyMixins],
        components:{
            returnvisitModule,
            Pagination,
        },
        name:'ymVisitReturn',
        data() {
            return {
                isFull:false,
                loadingPage:false,
                formInline:{
                    page: 1,
                    limit: 10,
                },
                orderstatics:{
                    totalmoney: 0,
                },
                defaultImg:require('../../assets/img/avatar.jpg'),
                pageparm: {
                    page: 1,
                    limit: 10,
                    total: 0
                },
                tableData:[],
                timers:[],
                statusList:[
                    {id:0,name:"待回访"},
                    {id:1,name:"已回访"},
                ]
            }
        },
        methods:{
            setFull(){
                this.isFull=!this.isFull
            },
            // 表格序列号
            getIndex($index) {
                // (当前页 - 1) * 当前显示数据条数 + 当前行数据的索引 + 1
                return (this.pageparm.page-1)*this.pageparm.limit + $index +1
            },
            addModule() {
                // this.$refs.addModuleFlag.addModuleFn(null,'新增','1')
            },
            handleEdit(row,flag) {
                let vm = this
                if(flag=='audit') {
                    this.$refs.returnVisitModuleFlag.auditModuleFn(row)
                }
                else if(flag=="reset"){
                    this.formInline = {
                        page:1,
                        limit: 10
                    }
                    this.pageparm={
                        page: 1,
                        limit: 10,
                        total: 0
                    }
                    this.timers = []
                    this.search()
                }
            },

            callFather(parm) {
                this.formInline.page = parm.page
                this.formInline.limit = parm.limit
                this.getData()
            },
            search() {
                this.formInline.page = 1
                this.formInline.limit = 10
                this.getData()
            },
            //获取列表
            async getData(){
                this.loadingPage = true
                yimeiReturnvisit(this.formInline).then(res => {
                     this.loadingPage = false
                     if(res.code ==2000) {
                         this.tableData = res.data.data
                         this.pageparm.page = res.data.page;
                         this.pageparm.limit = res.data.limit;
                         this.pageparm.total = res.data.total;
                         this.mutitabsStore.getReturnVisitNums()
                     }

                 })
            },
            timeChange(val){
                if (val) {
                    this.formInline.beginAt=dateFormats(val[0],'yyyy-MM-dd hh:mm:ss');
                    this.formInline.endAt=dateFormats(val[1],'yyyy-MM-dd hh:mm:ss');
                } else {
                    this.formInline.beginAt = null
                    this.formInline.endAt = null
                }
                this.search()
            },
            /**
             * 从URL里下载文件
            */
            // 下载文件
            downloadFile(url) {
                var iframe =document.createElement("iframe")
                iframe.style.display ="none";
                iframe.src = url;
                document.body.appendChild(iframe);
            },
            exportData() {
                // var params = {
                //     type:3,
                //     page: 1,
                //     limit: 9999,
                // }
                // retrieveFinancestatisticsExport(params).then(res => {
                //      if(res.code ==2000) {
                //          this.downloadFile(res.data.data)
                //          //this.$message.warning(res.data.data)
                //      }
                //  })
            }
        },
        created() {
            this.getData()
        },
    }
</script>
<style lang="scss">
    .order-static{
        display: flex;
        font-size: 14px;
        background: var(--l-order-statics);
        padding: 10px;
        li{
            margin-right: 30px;

        }
    }
</style>