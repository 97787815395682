<template>
    <div>
        <ly-dialog v-model="dialogVisible" width="680px" title="回访" :before-close="handleClose">
            <el-form :model="formData" :rules="rules" ref="rulesForm" label-position="right" label-width="auto" :disabled="formData.is_over">
                <el-form-item label="意向说明：">
                    <el-input type="textarea" :disabled="formData.is_over" v-model="formData.intention_desc" maxlength="100" show-word-limit clearable placeholder="请输入" style="width:100%"></el-input>
                </el-form-item>
                <el-form-item label="推荐项目：">
                    <el-table :data="formData.tj_projects" border style="width: 100%">
                        <el-table-column prop="project_name" label="项目名称" min-width="160" />
                        <el-table-column label="操作" fixed="right" width="80">
                            <template #default="scope">
                                <el-popconfirm width="200" title="确定删除该推荐项目吗？" @confirm="onDelRow(scope.row,scope.$index)">
                                    <template #reference>
                                        <span class="table-operate-btn">删除</span>
                                    </template>
                                </el-popconfirm>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-form-item>
                <el-form-item label="回访结果：" prop="result">
                    <el-input type="textarea" :disabled="formData.is_over" v-model="formData.result" maxlength="100" show-word-limit clearable placeholder="请输入" style="width:100%"></el-input>
                </el-form-item>
            </el-form>
            <template v-slot:footer>
                <el-button type="primary" @click="submitData" :loading="loadingSave" :disabled="formData.is_over">提交</el-button>
            </template>
        </ly-dialog>
    </div>
</template>

<script>
    import LyDialog from "@/components/dialog/dialog";
    import {yimeiReturnvisitVisit} from "@/api/api";
    import {deepClone} from "@/utils/util";
    export default {
        name: "returnvisitModule",
        components: {LyDialog},
        data() {
            return {
                dialogVisible:false,
                loadingSave:false,
                formData:{
                    id:'',
                    intention_desc:"",
                    result:"",
                    tj_projects:[],
                    is_over:false
                },
                rules:{
                    result: [
                        {required: true, message: '请填写回访结果',trigger: 'blur'}
                    ]
                },
            }
        },
        methods:{
            handleClose() {
                this.dialogVisible=false
                this.loadingSave=false
                this.formData = {
                    id:'',
                    intention_desc:"",
                    result:"",
                    tj_projects:[],
                    is_over:false
                }
            },
            // 配置的行删除
            onDelRow (row,index) {
                this.formData.tj_projects.splice(index,1)
            },
            auditModuleFn(item) {
                this.dialogVisible=true
                var tempdata = deepClone(item)
                this.formData.id = tempdata.id
                this.formData.intention_desc = tempdata.orderinfo.intention_desc
                if(!!item.result){
                    this.formData.result = tempdata.result
                }
                this.formData.tj_projects = tempdata.tj_projects
                this.formData.is_over = tempdata.is_over
            },
            submitData() {
                this.$refs['rulesForm'].validate(obj=>{
                    if(obj) {
                        if(this.formData.result === '' || !(!!this.formData.result)){
                            this.$message.warning("请填写回访结果")
                            return
                        }
                        this.loadingSave=true
                        yimeiReturnvisitVisit(this.formData).then(res=>{
                            this.loadingSave=false
                            if(res.code ==2000) {
                                this.$message.success(res.msg)
                                this.handleClose()
                                this.$emit('refreshData')
                            } else {
                                this.$message.warning(res.msg)
                            }
                        })
                    }
                })
            },

        }
    }
</script>

